<template>
  <section class="news-preview standard-padding">
    <div class="image-wrapper">
      <NuxtImgWrapper src="/img/elements/placeholder-news-teaser.webp" alt="" />
    </div>
    <article class="news">
      <h2>News</h2>
      <div class="content">
        <p class="date">13.Juli 2021</p>
        <h3>Borbet Leichtmetallräder setzt Spinworx® im Werkzeugbau ein</h3>
        <p class="text">
          Ziel erreicht: Höchste Prozesssicherheit mit Spinworx® Leichtmetall
          und Leidenschaft – aus diesen Elementen fertigt Borbet runde
          Meisterwerke, mit denen das Traditionsunternehmen seit Jahrzehnten
          Maßstäbe setzt.
        </p>
        <div class="button-wrapper">
          <nuxt-link to="#">
            <AppButton>Ganzen Artikel lesen</AppButton>
          </nuxt-link>
          <nuxt-link to="#">
            <AppButton>Alle News anzeigen</AppButton>
          </nuxt-link>
        </div>
      </div>
    </article>
    <div class="tool">
      <nuxt-link class="item-wrapper" to="#">
        <NuxtImgWrapper
          src="/img/elements/tools/placeholder-engraving-systems.webp"
          alt=""
        />
        <div class="content">
          <p class="pre-headline">Verwendetes Werkzeug</p>
          <p class="headline">Spinworx®</p>
          <p class="text">
            Im Einsatz bei Borbet: Spinworx®-Einschraubfräser in Systemgröße
            DR07 mit 25 mm Durchmesser und Schneidplatten r 3,5
          </p>
        </div>
        <Icon icon="chevron-right" />
      </nuxt-link>
    </div>
    <div class="material">
      <nuxt-link class="item-wrapper" to="#">
        <p class="headline">Bearbeitete Materialien</p>
        <div class="item">
          <p class="title">Aluminium</p>
          <p class="description">Nach DIN ISO 123456789:2016</p>
        </div>
        <Icon icon="chevron-right" />
      </nuxt-link>
    </div>
  </section>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.news-preview {
  font-size: max(1.5rem, 1.25vw); // 18
  @media (min-width: $bp-md) {
    display: flex;
    flex-wrap: wrap;
  }
}
.image-wrapper {
  margin-bottom: 0.2em;
  line-height: 0;
  @media (min-width: $bp-md) {
    order: 1;
    width: calc(#{$col-span-md * 4} - 0.2em);
    margin-bottom: 0.2em;
    margin-left: auto;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
}
.news {
  margin-bottom: 0.2em;
  padding-block: 2em;
  background: $color-inverted;
  .content {
    padding-inline: $column;
    .date {
      margin-bottom: 1em;
    }
    h3 {
      margin-bottom: 0.5em;
      font-weight: $weight-semibold;
    }
    .text {
      margin-bottom: 1.35em;
      font-size: inherit;
      line-height: 1.65;
    }
  }
  @media (min-width: $bp-md) {
    display: flex;
    margin-bottom: 0.2em;
    padding-block: unset;
    .content {
      order: 0;
      width: $col-span-md * 5;
      margin-right: $column-md;
      padding-block: $column-md;
      padding-inline: unset;
    }
  }
}
h2 {
  margin-bottom: 1.5em;
  margin-left: $column;
  font-size: max(1.4rem, 1.25vw); // 18
  font-weight: $weight-semibold;
  @media (min-width: $bp-md) {
    width: $col-span-md;
    margin-right: $gutter-md;
    margin-bottom: unset;
    margin-left: $column-md;
    padding-block: $column-md;
  }
}

.button-wrapper {
  > a {
    display: block;
    width: fit-content;
    margin-bottom: 1em;
  }
  @media (min-width: $bp-lg) {
    display: flex;
    > a:not(:last-of-type) {
      margin-right: $gutter-md;
    }
  }
}
.tool {
  margin-bottom: 0.2em;
  font-size: max(1.8rem, 1.25vw); // 18
  .item-wrapper {
    transition:
      scale 0.33s,
      box-shadow 0.33s;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-block: $column;
    padding-inline: $gutter * 3;
    z-index: 1;
    font-size: inherit;
    background: $color-inverted;
    @media (hover: hover) {
      &:hover {
        scale: 1.04;
        z-index: 2;
        box-shadow: $base-shadow;
        img {
          scale: 1.1;
        }
        svg {
          translate: 0.35em 0;
        }
      }
    }
  }
  img {
    order: 1;
    transition: scale 0.33s;
    align-self: center;
    aspect-ratio: 1;
    width: $column * 3 + $gutter * 2;
    height: fit-content;
    margin-top: 2em;
  }
  .content {
    width: $col-span * 7;
    margin-right: $gutter;
    margin-left: auto;
    .pre-headline {
      margin-bottom: 1.5em;
      font-size: $font-14;
      color: $color-secondary;
    }
    .headline {
      margin-bottom: 0.5em;
    }
    .text {
      font-size: $font-14;
    }
  }
  svg {
    transition: translate 0.33s;
    position: absolute;
    width: 0.35em;
    min-width: 0.35em;
    height: 0.57em;
    right: $gutter * 3;
  }
  @media (min-width: $bp-md) {
    order: 2;
    width: $col-span-md * 7 + $column-md;
    margin-bottom: 0;
    .item-wrapper {
      height: 100%;
      padding: $gutter-md * 2 $gutter-md;
    }
    img {
      order: 0;
      width: $col-span-md + $gutter-md;
      margin-top: unset;
      margin-right: $gutter-md;
    }
    .content {
      width: $col-span-md * 5;
      margin-left: unset;
    }
    svg {
      position: relative;
      right: unset;
    }
  }
}

.material {
  .item-wrapper {
    transition:
      scale 0.33s,
      box-shadow 0.33s;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: $column;
    background: $color-inverted;
    @media (hover: hover) {
      &:hover {
        scale: 1.04;
        z-index: 2;
        box-shadow: $base-shadow;
        img {
          scale: 1.1;
        }
        svg {
          translate: 0.35em 0;
        }
      }
    }
  }
  .headline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;
    font-size: $font-14;
    color: $color-darkgray;
  }
  svg {
    transition: translate 0.33s;
    position: absolute;
    width: 0.35em;
    min-width: 0.35em;
    height: 0.57em;
    top: $column;
    right: $gutter * 3;
    font-size: max(1.8rem, 1.25vw); // 18
    color: $color-text;
  }
  .title {
    margin-bottom: 0.5em;
    font-size: max(1.5rem, 1.25vw); // 18
    font-weight: $weight-semibold;
  }
  .description {
    font-size: $font-14;
  }
  @media (min-width: $bp-md) {
    order: 3;
    width: calc(#{$col-span-md * 4} - 0.2em);
    margin-left: auto;
    .item-wrapper {
      padding: $gutter-md * 2;
    }
    svg {
      top: $gutter-md * 2;
      right: $gutter-md;
    }
  }
}
</style>
