<template>
  <div
    class="fullscreen-vimeo"
    :class="{ fullscreen: state.fullscreen, portrait: portrait }"
  >
    <iframe
      ref="vimeoVideo"
      :src="
        'https://player.vimeo.com/video/' +
        vimeoId +
        '?dnt=true&pip=false&title=false&portrait=false&byline=false&background=' +
        (state.fullscreen ? 'false' : 'true')
      "
      allow="fullscreen"
      allowfullscreen
      :width="portrait ? '720' : '1280'"
      :height="portrait ? '1280' : '720'"
      frameborder="0"
      loading="lazy"
    ></iframe>
    <div
      v-if="!inline"
      class="icon-wrapper"
      @click="
        () => {
          state.fullscreen = false
          $emit('leave-fullscreen')
        }
      "
    >
      <Icon icon="x" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'

const props = defineProps<{
  vimeoId: string
  portrait?: boolean
  fullscreen?: boolean
  inline?: boolean
}>()

const emit = defineEmits(['leave-fullscreen'])

const state = reactive({
  fullscreen: false,
})

onMounted(() => {
  if (props.fullscreen) {
    state.fullscreen = true
  }
})

onKeyStroke(['Escape', 'GoBack'], (e) => {
  if (state.fullscreen) {
    state.fullscreen = false
    emit('leave-fullscreen')
    e.preventDefault()
  }
})
</script>

<style lang="scss" scoped>
.fullscreen-vimeo {
  position: relative;
  width: 100%;
  line-height: 0;
  &.fullscreen {
    opacity: 0.6;
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: black;
    iframe {
      height: 100%;
    }
  }
  &.portrait {
    iframe {
      aspect-ratio: 9/16;
    }
  }
  iframe {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
  }
  .icon-wrapper {
    cursor: pointer;
    position: absolute;
    top: $offset;
    right: $offset;
    &:after {
      content: '';
      position: absolute;
      width: 200%;
      height: 200%;
      top: -50%;
      left: -50%;
    }
    svg {
      width: 3.25vw;
      min-width: 3.5rem;
      height: 3.25vw;
      min-height: 3.5rem;
      color: $color-lightgray;
    }
  }

  @media (min-height: $bp-md) {
    .icon-wrapper {
      top: $offset-md;
      right: $offset-md;
    }
  }
}
</style>
