<template>
  <section class="home">
    <HeroWithImage
      :headline="{
        aside: 'Wir sind pokolm®',
        title: 'Ihr starker Partner für alle Bereiche der Zerspanung.',
        text: 'Zuverlässige Speziallösungen für verschiedenste Branchen …',
      }"
      :image="{
        source: '/img/placeholder-hero.webp',
        alt: '@todo: Placeholder',
        width: 1680,
        height: 1244,
      }"
      :background-image="{
        source: '/img/backgrounds/placeholder-hero-background.webp',
      }"
      :anchor-list="[
        { title: 'Werkzeug- & Formenbau', name: 'toolmaking' },
        { title: 'Aerotechnik', name: 'aerotechnics' },
        { title: 'Medizintechnik', name: 'medical-technology' },
        { title: 'Energietechnik', name: 'power-engineering' },
        { title: 'Lebensmitteltechnik', name: 'food-technology' },
        { title: 'Maschinenbau', name: 'engineering' },
        { title: 'Turbinenbau', name: 'turbine-construction' },
      ]"
      :active-anchor="state.activeSection"
      @anchor="state.activeSection = $event"
    />

    <div v-color-changer="'light'"></div>

    <SectorSlider
      :sector-list="sectorList"
      :active-item="state.activeSection"
      @active-item="state.activeSection = $event"
    />

    <section class="products">
      <div class="intro">
        <h2>Produktreihen</h2>
        <p>
          Einleitender Text zu den Produktreihen. Dieser sollte mindestens zwei
          Zeilenoder drei Zeilen lang sein, damit es harmonisch aussieht.
        </p>
      </div>
      <ToolLinkElementList />
    </section>

    <NewsPreview />

    <EarthBox title="Made in Germany.<br>Operating worldwide." button />

    <Separator />

    <AttentionHeadline
      aside="Das ist pokolm"
      :button="{ title: 'Mehr über pokolm erfahren', route: '#', red: true }"
    >
      {{ $t('test') }}
    </AttentionHeadline>
    <FullscreenVimeo class="standard-padding" vimeo-id="408304689" inline />
  </section>
</template>

<script lang="ts" setup>
import metaData from '~/config/seo-data'
import SectorSlider from '~/components/SectorSlider.vue'
import sectorList from '~/config/sectors'

definePageMeta({
  title: 'Fräswerkzeuge, Aufnahmesysteme und Frässtrategieberatung',
  description:
    'Ihr starker Partner für alle Bereiche der Zerspanung. Immer zuverlässig. Immer zukunftsorientiert.',
  pageType: 'dark',
})
// 'Hochwertige Fräswerkzeuge und Aufnahmesysteme. Zudem bietet die Firma Frässtrategierberatung für komplexe 2,5 und 3D-Geometrien.',

const state = reactive({
  activeSection: null,
})

const pageName = 'home'
// useHead({
//   title: metaData[pageName].title,
//   meta: [
//     { name: 'description', content: metaData[pageName].description },
//     {
//       key: 'og:image',
//       property: 'og:image',
//       content: metaData[pageName].image,
//     },
//     {
//       key: 'og:title',
//       property: 'og:title',
//       content: metaData[pageName].title,
//     },
//     {
//       key: 'og:description',
//       property: 'og:description',
//       content: metaData[pageName].description,
//     },
//   ],
// })
</script>

<style lang="scss" scoped>
.products {
  padding-block: $offset * 3 $offset * 2;
  padding-inline: $offset;
  .intro {
    margin-bottom: 2em;
    font-size: $font-28;
  }
  @media (min-width: $bp-md) {
    padding-block: $offset-md;
    padding-inline: $offset-md;
    .intro {
      display: flex;
      margin-bottom: 3.2em;
      h2 {
        width: $col-span-md * 3;
        padding-right: $col-span-md;
        font-size: max(1.4rem, 1.25vw); // 18
        font-weight: $weight-normal;
      }
      p {
        width: $col-span-md * 7;
        font-size: $font-28;
        font-weight: $weight-light;
      }
    }
  }
}
.fullscreen-vimeo {
  @media (min-width: $bp-md) {
    padding-top: unset;
  }
}
.separator {
  color: $color-primary;
}
</style>
