<template>
  <section class="sector-slider">
    <nav>
      <div class="arrow left">
        <Icon icon="chevron-right" @click="previousItem()" />
      </div>
      <transition name="fade" mode="out-in">
        <div
          v-if="swiperEl"
          class="active-item-name"
          :key="'active-item-name-' + activeItem"
        >
          {{ $t(sectorList[swiperEl.swiper.realIndex].title) }}
        </div>
      </transition>
      <ul>
        <li
          v-for="el of sectorList"
          :id="el.name"
          :class="{ active: activeItem === el.name }"
          :key="'sector-list-item-' + el.name"
          @click="$emit('active-item', el.name)"
        >
          {{ $t(el.title) }}
        </li>
      </ul>
      <div class="arrow right">
        <Icon icon="chevron-right" @click="nextItem()" />
      </div>
    </nav>
    <div class="aside-headline">
      <h2>Branchen</h2>
    </div>
    <client-only>
      <swiper-container
        ref="swiperEl"
        slides-per-view="1"
        speed="500"
        loop="true"
      >
        <swiper-slide v-for="el of sectorList">
          <section class="sector-item" :key="'sector-' + el.name">
            <h3>{{ $t(el.headline) }}</h3>
            <p>{{ $t(el.text) }}</p>
            <nuxt-link :to="el.route">
              <AppButton red>Zu {{ $t(el.title) }}</AppButton>
            </nuxt-link>
          </section>
          <NuxtImgWrapper :src="el.backgroundImage.source" alt="" />
        </swiper-slide>
      </swiper-container>
    </client-only>
  </section>
</template>

<script lang="ts" setup>
import type { Sector } from '~/types/base'
import { watchEffect } from '@vue/runtime-core'

// TODO: the slider images (el.backgroundImage.source) need also to be rendered statically
// linksWithChildren.forEach((link) => {
//   Object.values($img.options.screens).forEach((width) => {
//     link.children?.forEach((child) => {
//       if (child.img && child.img.height && child.img.width) {
//         $img.getImage(child.img.src, {
//           modifiers: {
//             width,
//             height: width * (child.img.height / child.img.width),
//             format: 'webp',
//           },
//         })
//       } else if (child.img) {
//         $img.getImage(child.img.src, {
//           modifiers: {
//             width,
//             format: 'webp',
//           },
//         })
//       }
//     })
//   })
// })

const props = defineProps<{
  sectorList: Sector[]
  activeItem: string | null
}>()

const emit = defineEmits(['active-item'])

const swiperEl = ref()

onMounted(() => {
  swiperEl.value?.addEventListener('slidechange', onSlideChange)
})

function previousItem() {
  swiperEl.value?.swiper?.slidePrev()
}

function nextItem() {
  swiperEl.value?.swiper?.slideNext()
}

function onSlideChange() {
  const realIndex = swiperEl.value?.swiper?.realIndex
  emit('active-item', props.sectorList[realIndex]?.name)
}

watchEffect(() => {
  const activeElement = props.sectorList?.find(
    (x: any) => x.name === props.activeItem
  )
  if (!activeElement) {
    return false
  }
  const newIndex = props.sectorList.indexOf(activeElement)
  swiperEl.value?.swiper?.slideTo(newIndex)
})
</script>

<style lang="scss" scoped>
@mixin activeItem {
  content: '';
  pointer-events: none;
  transition: opacity, translate, color;
  transition-duration: 0.25s;
  opacity: 0;
  translate: 0 0.1em;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -0.25em;
  left: 0;
  background: currentColor;
}
.sector-slider {
  position: relative;

  @media (min-width: $bp-md) {
    //padding-left: $offset-md;
  }
}
nav {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: $gutter-md;
  width: 100%;
  padding-inline: $offset;
  top: 1.5em;
  z-index: 2;
  font-size: max(1.6rem, 1.25vw); // 18
  .arrow {
    cursor: pointer;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 300%;
      height: 160%;
      top: -30%;
      left: -100%;
    }
    &.left {
      svg {
        scale: -1 1;
      }
    }
    svg {
      transition: translate 0.2s;
      width: 0.4em;
      height: 0.8em;
    }
    @media (hover: hover) {
      &:hover {
        &.left {
          svg {
            translate: -0.15em 0;
          }
        }
        &.right {
          svg {
            translate: 0.15em 0;
          }
        }
      }
    }
  }
  .active-item-name {
    position: relative;
    color: $color-primary;
    &:after {
      @include activeItem;
      opacity: 1;
      translate: 0;
    }
  }
  ul {
    display: none;
  }
  li {
    cursor: pointer;
    transition: color 0.25s;
    position: relative;
    letter-spacing: -0.015em;
    &:after {
      @include activeItem;
    }
    &.active {
      color: $color-primary;
      &:after {
        opacity: 1;
        translate: 0;
      }
    }
    @media (hover: hover) {
      &:hover {
        &:after {
          opacity: 1;
          translate: 0;
        }
      }
    }
  }
  @media (min-width: $bp-md) {
    justify-content: unset;
    padding-inline: calc(#{$offset-md} - 1.5em);
    z-index: 3;
    .active-item-name {
      display: none;
    }
    ul {
      display: flex;
      column-gap: $gutter-md;
    }
    li {
      min-width: fit-content;
    }
  }
}
.aside-headline {
  position: absolute;
  top: 10em;
  left: $offset;
  z-index: 1;
  h2 {
    font-size: max(1.4rem, 1.25vw); //18
    font-weight: $weight-normal;
  }
  @media (min-width: $bp-md) {
    top: 17em;
    left: $offset-md;
  }
}
swiper-slide {
  position: relative;
  padding-block: 12em 10em;
  img {
    opacity: 0.2;
    position: absolute;
    object-fit: cover;
    height: 100%;
    inset: 0;
  }
  @media (min-width: $bp-md) {
    padding-block: 17em 9em;
  }
}
.sector-item {
  position: relative;
  margin-inline: $offset;
  z-index: 1;
  h3 {
    width: 100%;
    margin-bottom: 0.35em;
    font-size: $font-50;
    font-weight: $weight-normal;
    color: $color-primary;
  }
  p {
    width: 100%;
    margin-bottom: 1em;
    font-size: $font-28;
    font-weight: $weight-light;
    line-height: 1.6;
  }
  @media (min-width: $bp-md) {
    margin-left: $offset-md + ($col-span-md * 3);
    margin-right: unset;
    h3 {
      width: $col-span-md * 6;
    }
    p {
      width: $col-span-md * 8;
    }
  }
}
</style>
